import '../styles/globals.css'
import type {AppProps} from 'next/app'
import {config} from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import {SSRProvider} from '@react-aria/ssr';
import {useEffect} from 'react';
import splitbee from '@splitbee/web';

config.autoAddCss = false
const SPLITBEE_TOKEN = process.env.NEXT_PUBLIC_SPLITBEE_TOKEN

function MyApp({Component, pageProps}: AppProps) {
    useEffect(() => {
        splitbee.init({
            token: SPLITBEE_TOKEN,
            scriptUrl: '/bee.js',
            apiUrl: '/_hive',
            disableCookie: true
        })
    }, [])

    return (
        <SSRProvider>
            {/*<EaseOverlayProvider>*/}
            <>
                {/*<EaseAdminBar/>*/}
                <Component {...pageProps} />
            </>
            {/*</EaseOverlayProvider>*/}
        </SSRProvider>
    )
}

export default MyApp
